import React from 'react';
import Home from '../pages/Home';
import Menu from '../pages/Menu';
import About from '../pages/About';

interface RouteType {
  path: string;
  component: React.ComponentType<any>;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: "/",
    component: Home,
    name: "Home",
    protected: true,
  },
  {
    path: "/menu",
    component: Menu,
    name: "Menu",
    protected: true,
  },
  {
    path: "/about",
    component: About,
    name: "About Us",
    protected: true,
  },
];

export default routes;
