import { AppBar, Container, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Button } from "@mui/material";
import { useEffect, useState } from "react";
import useStyles from "../styles/useStyles";
import { Menu as MenuIcon, Close } from "@mui/icons-material";
import logo from '../images/logo.png'
import { useResponsiveness } from "../hooks/useResponsiveness";
import { useNavigate } from 'react-router-dom';
const pages = ['Home'];

function ResponsiveAppBar() {
    const classes = useStyles();
    const navigate  = useNavigate()
    const { isDevice } = useResponsiveness();
    const [open, setOpen] = useState(false);
    const [activePage, setActivePage] = useState('Home'); // Step 1: State for active page
    const toggleMenu = () => {
        setOpen(prev => !prev);
    };
    const handlePageClick = (page: string) => {
        setActivePage(page);
        navigate(page === 'Home' ? '/' : `/${page}`);
    };

    useEffect(() => {
        const currentPath = window.location.pathname;
        setActivePage(currentPath === '/' ? 'Home' : currentPath.substring(1));
    }, []);

    return (
        <AppBar position="fixed" className={classes.fixedNavbar}>
            <Container style={{ maxWidth: '100%' }}>
                <Toolbar disableGutters>
                    {/* Desktop View */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }} >
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: '#F25600',
                                textDecoration: 'none',
                            }}
                        >
                            {process.env.REACT_APP_WEBSITE_NAME}
                        </Typography>
                 
                        {pages.map((page, key) => (
                            <Button
                                key={key}
                                onClick={() => handlePageClick(page)}
                                sx={{
                                    color: page === activePage ? '#F25600' : '#222023',
                                    '&:hover': {
                                        color: '#F25600',
                                    },
                                    '&:active': {
                                        textDecoration: 'underline',
                                        textDecorationColor: '#F25600',
                                    }
                                }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                    {/* Mobile View */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                alignItems: 'center',
                            }}
                          component='a'
                          href="/"
                        >
                            <img src={logo} alt='Logo - Tidy Bird' width='65px' height='65px' />
                        </Box>

                        <IconButton
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                transition: 'transform 0.3s',
                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
                            }}
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleMenu}
                        >
                            {!open ? <MenuIcon /> : <Close />}
                        </IconButton>
                    </Box>
                    <Menu
                        id="menu-appbar"
                        open={open}
                        onClose={toggleMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        style={{ position: 'fixed', top: '50px', left: '8px' }}
                    >
                        {pages.map((page, key) => (
                            <MenuItem key={key} onClick={() => {handlePageClick(page);  toggleMenu();}}>
                                <Typography textAlign="center" color={page === activePage ? '#F25600' : '#1F1F1F'}>
                                    {page}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
