import React, { useEffect } from "react";
import useStyles from "../styles/useStyles";
import { Box } from "@mui/material";

interface IMainContainerProps {
  children?: React.ReactNode;
  title?: string;
  className?: string;
}

const MainContainer: React.FunctionComponent<IMainContainerProps> = ({ children, title = `${process.env.REACT_APP_WEBSITE_NAME}`, className = ""}) => {
  const classes = useStyles();

  useEffect(() => {
    document.title = title;
  }, [title]);

  const containerStyle: React.CSSProperties = {
    
 
  };

  return (
    <Box style={containerStyle} className={`${classes.root} ${className}`} component="main">
      {children}
    </Box>
  );
};

export default MainContainer;














