import { Box, Grid, Typography } from "@mui/material"
import MainContainer from "../Components/MainContainer"
import CustomCard from "./components/CustomCard"



const Menu = () => {
    const cardsData = [
        { title: "first", description: "", image: "", alt: "image", url: "" },
        { title: "second", description: "", image: "", alt: "image", url: "" },
        { title: "third", description: "", image: "", alt: "image", url: "" },
        { title: "fourth", description: "", image: "", alt: "image", url: "" },
        { title: "fifth", description: "", image: "", alt: "image", url: "" },
        { title: "sixth", description: "", image: "", alt: "image", url: "" },
        { title: "seventh", description: "", image: "", alt: "image", url: "" },
        { title: "eighth", description: "", image: "", alt: "image", url: "" },
        { title: "ninth", description: "", image: "", alt: "image", url: "" },
        { title: "tenth", description: "", image: "", alt: "image", url: "" },
        { title: "eleventh", description: "", image: "", alt: "image", url: "" },
        { title: "twelfth", description: "", image: "", alt: "image", url: "" },
        { title: "thirteenth", description: "", image: "", alt: "image", url: "" },
        { title: "fourteenth", description: "", image: "", alt: "image", url: "" },
        { title: "fifteenth", description: "", image: "", alt: "image", url: "" },
        { title: "sixteenth", description: "", image: "", alt: "image", url: "" },
        { title: "seventeenth", description: "", image: "", alt: "image", url: "" },
        { title: "eighteenth", description: "", image: "", alt: "image", url: "" },
        { title: "nineteenth", description: "", image: "", alt: "image", url: "" },
        { title: "twentieth", description: "", image: "", alt: "image", url: "" }
        // You can add more objects in a similar manner if needed
    ];


    return (
        <MainContainer title={process.env.REACT_APP_WEBSITE_NAME as string}>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '80%',
                padding: '20px',
                marginTop: '100px',
            }}
        >
            <Typography component='h1' sx={{ marginBottom: '50px' }}>Ready to Take Out Some Flavor?</Typography>

            <Grid container justifyContent="center" spacing={2}>
                {cardsData.map((card, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                        <CustomCard {...card} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    </MainContainer>
);
};

export default Menu