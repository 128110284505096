import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import routes from './config/routes';
import Navbar from './Components/Navbar';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import Footer from './Components/Footer';
import CustomProgress from './Components/CustomProgress';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeLoading = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(fakeLoading);
  }, []);

  const theme = createTheme({
    palette: {
      background: {
        default: '#F5F1EF',
      },
    },
  });

  const RedirectComponent: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate("/", { replace: true });
    }, [navigate]);

    return null;
  };

  const renderRoutes = () => {
    return (
      <Routes>
        {routes?.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
        <Route path="*" element={<RedirectComponent />} />
      </Routes>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename="/">
        <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
          <Navbar />
          {isLoading ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
              }}
            >
              <CustomProgress />
            </Box>
          ) : (
            renderRoutes()
          )}
          <Footer />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
