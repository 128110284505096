import { Box, Grid, MenuItem } from "@mui/material";
import MainContainer from "../Components/MainContainer";
import Spacer from "../Components/Spacer";
import tidyBirdImage from "../images/items/tidyBirdImg.jpeg";
import { Suspense, lazy, useState } from "react";
import { MenuItemData } from "../types/menuItem.types";
import CustomProgress from "../Components/CustomProgress";
import { useResponsiveness } from "../hooks/useResponsiveness";

const Home = () => {
  const LazyHeroCard = lazy(() => import("./components/HeroCard"));
  const LazyMenuItems = lazy(() => import("./components/MenuItems"));
  const {isDevice} = useResponsiveness()
  const [menuItems, setMenuItems] = useState<MenuItemData[]>(
    [
      {
        image: tidyBirdImage,
        title: "TidyBird Duo",
        description: "Buttermilk crispy fried chicken tenders, perfectly spiced and paired with zesty white bbq sauce (2Pieces).",
        price: 12.95,
        url: 'https://www.ubereats.com/ca/store/tidy-bird/2HAV9ZMyX7KxtZuAoMZq-A/a69ddf7a-b42c-4311-8ca4-9e93619eaa8f/7e5be7d2-324d-4490-910a-21760060bb47/bf816940-6601-4f81-9d18-fbae36ede14c'
      },
      {
        image: tidyBirdImage,
        title: "Southern Crunch Wrap",
        description: "Indulge in the ultimate Crunch Wrap loaded with chicken, mozzarella, vibrant purple cabbage, and zesty pico de Gallo.",
        price: 13.95,
        url: 'https://www.ubereats.com/ca/store/tidy-bird/2HAV9ZMyX7KxtZuAoMZq-A/a69ddf7a-b42c-4311-8ca4-9e93619eaa8f/7e5be7d2-324d-4490-910a-21760060bb47/8e27fb27-e374-4021-8daa-8f96d249102b'
      },
      {
        image: tidyBirdImage,
        title: "Crispy Brioche Bliss",
        description: "Experience the heartiness of our Crispy Chicken Sandwich with the comforting touch of brioche, house white bbq, and purple slaw.",
        price: 13.95,
        url: 'https://www.ubereats.com/ca/store/tidy-bird/2HAV9ZMyX7KxtZuAoMZq-A/a69ddf7a-b42c-4311-8ca4-9e93619eaa8f/7e5be7d2-324d-4490-910a-21760060bb47/4c46d44d-afed-4ed4-963b-62e02f14555a'
      },
      {
        image: tidyBirdImage,
        title: "Wrap n' Roll",
        description: "Wrap up your cravings with our Fried Chicken Wrap featuring a zesty, tangy twist.",
        price: 13.95,
        url: 'https://www.ubereats.com/ca/store/tidy-bird/2HAV9ZMyX7KxtZuAoMZq-A/a69ddf7a-b42c-4311-8ca4-9e93619eaa8f/7e5be7d2-324d-4490-910a-21760060bb47/9c105983-20e1-4d18-9c4e-377ad2c498ee'
      },
      {
        image: tidyBirdImage, 
        title: "The SideChick",
        description: "Tantalize your taste buds with our signature buttermilk crispy fried chicken tender in a bed of garlic aioli and potato buns.",
        price: 9.95,
        url: 'https://www.ubereats.com/ca/store/tidy-bird/2HAV9ZMyX7KxtZuAoMZq-A/a69ddf7a-b42c-4311-8ca4-9e93619eaa8f/7e5be7d2-324d-4490-910a-21760060bb47/7c0549d2-527a-4d2e-bcf9-07dd8d9b0474'
      },
      {
        image: tidyBirdImage,
        title: "Crispy Chilli Onion Feast",
        description: "Compliment your meal with our Crispy chilli onion fries, perfectly crispy and served with our signature house mayo.",
        price: 9.95,
        url: 'https://www.ubereats.com/ca/store/tidy-bird/2HAV9ZMyX7KxtZuAoMZq-A/a69ddf7a-b42c-4311-8ca4-9e93619eaa8f/90f273f5-d70e-4d67-b072-47221da9b85f/e7dc388a-9709-42e1-9987-3fbb61d51f9b'
      },
    ].map((item) => ({
      ...item,
      alt: item.title,
    }))
  );

  return (
    <MainContainer title={process.env.REACT_APP_WEBSITE_NAME as string}>
 
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: isDevice ?  "100%" : "90%",
            padding: '50px 25px 0px 25px',
          }}
        >
          <Suspense fallback={<CustomProgress type="linear" />}>
            <LazyHeroCard />{" "}
          </Suspense>
        </Box>

        <Spacer />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            padding: "20px",
          }}
        > 
        <Suspense fallback={<CustomProgress type="linear" />}>
          <LazyMenuItems menuItems={menuItems} />
        </Suspense>
        </Box>
    
    </MainContainer>
  );
};

export default Home;
