import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CustomButton from './CustomButton';
import useStyles from '../../styles/useStyles';
import { useNavigate } from 'react-router-dom';
interface CustomCardProps {
  title: string;
  description: string;
  image: string;
  alt: string;
  price?: number
  url: string
}

const CustomCard: React.FC<CustomCardProps> = ({ title, description, image, alt, price, url }) => {
  const navigate  = useNavigate()
  const redirectOrder = () => {
    const newTab = window.open(url, 'noopener');
    if (newTab) {
      setTimeout(() => {
        navigate(url);
      }, 100);
    }
  }
  const classes = useStyles();
  return (

      <Card className={classes.card}>
        <CardMedia
          component="img"
          alt={alt}
          image={image}
          style={{ objectFit: 'cover', height: 200}}
        />
        <CardContent style={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            ${price?.toFixed(2)}
          </Typography>
        </CardContent>
        <CardActions style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', height: 60 }}>
            <CustomButton size="small" variant="outlined" onClick={redirectOrder}>
              Order Now
            </CustomButton>
          </CardActions>
      </Card>

  );
}

export default CustomCard;
