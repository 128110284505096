import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/system';

interface CustomButtonProps extends ButtonProps {
  btnColor?: string;
  hoverColor?: string;
}

const StyledButton = styled(Button)(({ btnColor, hoverColor }: CustomButtonProps) => ({
color: '#F25600',
borderColor: '#F25600',
  borderRadius: '15px',
  '&:hover': {
    backgroundColor: 'rgba(242, 86, 0, 0.1)',
    border: '2px solid #F25600', 
  },
}));

const CustomButton: React.FC<CustomButtonProps> = (props: any) => {
  return <StyledButton {...props} />;
};

export default CustomButton;
