import { useMediaQuery, useTheme } from '@mui/material';

export const useResponsiveness = () => {
  const theme = useTheme();
  const isDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isDeviceTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isInBetween = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isDeviceDesktop = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  return {
    isDevice,
    isDeviceTablet,
    isDeviceDesktop,
    isDesktop,
    isInBetween
  } as const;
}
