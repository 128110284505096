import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

// Declare the CustomProgressProps interface
interface CustomProgressProps {
  type?: 'linear' | 'circular';
  value?: number;
  color?: string;
  height?: string | number
}

export const CustomProgress: React.FC<CustomProgressProps> = ({ type = 'circular', value, color = '#F25600', height }) => {
  switch (type) {
    case 'linear':
      return (
        <Box sx={{ position: 'absolute', top: '70%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', height: height }}>
          {value !== undefined ? (
            <LinearProgress
              variant="determinate"
              value={value}
              sx={{
                '.MuiLinearProgress-colorPrimary': { backgroundColor: color },
                '.MuiLinearProgress-barColorPrimary': { backgroundColor: color }
              }}
            />
          ) : (
            <LinearProgress
              sx={{
                '.MuiLinearProgress-colorPrimary': { backgroundColor: color },
                '.MuiLinearProgress-barColorPrimary': { backgroundColor: color }
              }}
            />
          )}
        </Box>
      );

    case 'circular':
      const sx = {
        color,
        marginTop: "50px",
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center'
      };
      return <CircularProgress sx={sx} value={value} />;

    default:
      return null;
  }
};

export default CustomProgress;
