import { Instagram, Phone, LocationOn, Email } from "@mui/icons-material";
import { Button, Container, Box, Grid, Typography, useTheme, Divider, } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from '../images/logo.png'
import moment from 'moment';

const Footer: React.FC = () => {
    const theme = useTheme();
    const StyledFooter = styled('footer')(() => ({
        backgroundColor: '#222023',
        padding: theme.spacing(2, 0),
        color: '#fff',
        marginTop: '25px'
    }));

    const linkItems = [
        { href: "/", src: logo, alt: "Tidy Bird Toronto Kitchen" },
        { href: "/", icon: <Instagram style={{ color: 'white' }} /> },
        { href: "tel: 6478674290", icon: <Phone style={{ color: 'white' }} /> },
        { href: "mailto: info@tidybird.ca", icon: <Email style={{ color: 'white' }} /> },
        { href: "https://maps.app.goo.gl/bgAQ8aH5akxinqh28", icon: <LocationOn style={{ color: 'white' }} /> }
    ];
    return (
        <StyledFooter>
            <Container>
                <Box py={4}>

                    {/* Top Menu Links */}

                    <Box style={{ display: 'flex' }}>
                        <LocationOn style={{ marginRight: 10, fontSize: 'medium' }} />
                        <Typography variant="body2" component='a' href="https://maps.app.goo.gl/bgAQ8aH5akxinqh28" style={{ color: 'white' }}>212 King St W, Toronto, ON M5H-1K5</Typography>
                    </Box>
                    <Box style={{ display: 'flex' }}>
                        <Phone style={{ marginRight: 10, fontSize: 'medium' }} />
                        <Typography variant="body2" component='a' href="tel: 6478674290" style={{ color: 'white' }}>647-867-4290</Typography>
                    </Box>
                    <Box style={{ display: 'flex' }}>
                        <Email style={{ marginRight: 10, fontSize: 'medium' }} />
                        <Typography variant="body2" component='a' href="mailto: info@tidybird.ca" style={{ color: 'white' }}>info@tidybird.ca</Typography>
                    </Box>

                    <Divider orientation="vertical" style={{ height: '1px', backgroundColor: '#F25600', marginTop: 25 }} />
                    <Box my={4}>
                        <Typography variant="body2">
                            The images provided are for illustrative purposes exclusively, and the real product might exhibit variations as a result of product improvements. Pricing is subject to change.
                        </Typography>
                    </Box>
                    <Divider orientation="vertical" style={{ height: '1px', backgroundColor: '#F25600', marginBottom: 25 }} />
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2">
                            © {moment().year()} {process.env.REACT_APP_WEBSITE_NAME}
                        </Typography>

                        {/* Social Icons */}
                        <Box display="flex" alignItems="center">
                            {linkItems.map((item, index) => (
                                <Typography component='a' href={item.href} key={index} style={{ marginRight: '10px', color: 'white', textDecoration: 'none' }}>
                                    {item.src ? (
                                        <img src={item.src} alt={item.alt} width='50px' height='50px' />
                                    ) : (
                                        item.icon
                                    )}
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                </Box>
            </Container>
        </StyledFooter>
    );
}

export default Footer;